import React from "react";
import ReactDOM from "react-dom/client";
import { StyledEngineProvider, CssBaseline } from "@mui/material";
import "./index.css";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <CssBaseline />
      <App />
    </StyledEngineProvider>
    <CssBaseline />
  </React.StrictMode>
);
