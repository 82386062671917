import axios from "axios";
let org_url = "https://api.calendly.com/organizations/DDHEVSTIR2ZNWQBN";
let count = 100;
let auth_code = "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjU4NzI4OTE1LCJqdGkiOiI3NDMxMWRlZS0yZjhkLTRkNDctOWI5OS1hYTViYTIyMDk4ZTIiLCJ1c2VyX3V1aWQiOiJBRUJBUVhVT1RTQlRZTDJEIn0.t17H7Ku9x6oNd0_InmAX7TYaqb-_YBr2fQu_WHWg3KG4zpphHdAYbpZsMctJXzrd6Oj5HsHdoBpJcobJUqGojg";

export function getDoctorList() {
    return axios.get(`https://api.calendly.com/event_types?organization=${org_url}&count=${count}`,
    {   
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth_code,
        }
    }).then((response)=> {
        let datas = response.data.collection;
        if(datas){
            return datas;
        } 
    })
    .catch(err=>{
        console.log("error in fetching", err);
    })
}
   