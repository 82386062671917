import React, { useState, useEffect } from "react";
import { Typography, styled, Grid } from "@mui/material";
import { getDoctorList } from "./apiService";
import DoctorCalendar from "./DoctorCalendar";
import DoctorList from "./DoctorList";
// import Data from "./data";
const H2 = styled("h2")(({ theme }) => ({
  fontSize: "2.3rem",
  fontWeight: "bold",
  width:"98%",
  // marginBottom: '16px',
  textAlign: "center",
  textTransform: "capitalize",
  color: "#022B60",// theme.palette.text.secondary,
}));

const App = () => {
  const [listData, setListata] = useState(null);
  const [selectedDoctor, setselectedDoctor] = useState(
    {
      label: 'Dr. Ajay Jain',
      link: 'https://calendly.com/radixhealthcare/book-your-appointment-with-dr-ajay-jain'
  });
  const [loading, setloading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setloading(true);
      const doctorsList = await getDoctorList();
      let tmpList = [];
      // console.log("first", doctorsList);
      for (let index = 0; index < doctorsList.length; index++) {
        if (doctorsList[index].active) {
          tmpList.push({
            label: 'Dr. ' + doctorsList[index].scheduling_url
              .split("dr-")[1]
              .replace(/-/g, " ")
              .toLowerCase()
              .split(' ')
              .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
              .join(' ')
              ,
            link: doctorsList[index].scheduling_url,
          });
        }
      }
      setListata(tmpList);
      setloading(false);
    }
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <Grid container >
        <Grid item md={12} xs={12} sm={12}>
          <H2>Book Your Doctor</H2>
        </Grid>
        {!loading && listData &&(
          <>
            <Grid item md={12} xs={12} sm={12}>
              <DoctorList doctorList={listData} selectedDoctor={selectedDoctor} setselectedDoctor={setselectedDoctor}/>
            </Grid>
            <Grid item md={12} xs={12} sm={12}>
              <DoctorCalendar selectedDoctor={selectedDoctor}/>
            </Grid>
          </>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default App;
