import React, {Fragment } from "react";
import { styled } from "@mui/material";
import { useCalendlyEventListener, InlineWidget } from "react-calendly";

const ContentBox = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: { margin: "16px" },
}));

const DoctorCalendar = ({ selectedDoctor }) => {
  // console.log(selectedDoctor)
  //   useEffect(() => {
  //     const head = document.querySelector('head');
  //     const script = document.createElement('script');
  //     script.setAttribute(
  //       'src',
  //       'https://assets.calendly.com/assets/external/widget.js'
  //     );
  //     head.appendChild(script);
  //   }, []);
  useCalendlyEventListener({
    onEventScheduled: (e) => {
      window.location.href='/'
    }
  });
  return (
    <Fragment>
      <ContentBox >
        <InlineWidget
          url={
            selectedDoctor?.link +
            "?hide_event_type_details=1&hide_gdpr_banner=1"
          }
        />
      </ContentBox>
    </Fragment>
  );
};

export default DoctorCalendar;
