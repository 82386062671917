import React, { Fragment } from "react";
import { Autocomplete, TextField, styled } from "@mui/material";

const ContentBox = styled("div")(({ theme }) => ({
  display:"flex",
  justifyContent:"center"
  // margin: "0 auto !important",
  // [theme.breakpoints.down("sm")]: { margin: "0px auto" },
}));

const DoctorList = ({doctorList, selectedDoctor, setselectedDoctor}) => {
  return (
    <Fragment>
      <ContentBox>
        <Autocomplete
          disablePortal
          disableClearable
          name="doctorInput"
          value={selectedDoctor}
          style={{ width: 300, color:'red' }}
          onChange={(event, value) => setselectedDoctor(value)} // prints the selected value
          id="doctorInput"
          options={doctorList}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField 
            inputProps={{style: {textTransform: 'capitalize'}}} 
            {...params} label="Doctors Name" />}
        />
      </ContentBox>
    </Fragment>
  );
};

export default DoctorList;
